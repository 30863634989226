exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2KZUiUWrvWBFPRy29xYVK7{height:80vh;width:80vw;margin-top:20px}.NC2KOFDZLwqfG809c1VQk{width:80vw}._2vwRxf2DcD5JZ4L1mYIPAj{padding:15px 20px}._1OtzAo-P7_UObrnhVSD-_S{display:grid;height:100%;grid-template-columns:1fr;grid-template-rows:auto minmax(200px,1fr) auto;grid-template-areas:\"Query-Controls\" \"Visualization\" \"Footer\"}._2LYYZCVpn8KOHcrQeeVAb7{grid-area:Query-Controls}._2at144Njr8RXES-9Qoh5Fi{grid-area:Visualization;overflow:hidden}._3LyEBN4C2b31ykcXeMv6aW{grid-area:Footer}", ""]);

// exports
exports.locals = {
	"editWidgetDialog": "_2KZUiUWrvWBFPRy29xYVK7",
	"editWidgetControls": "NC2KOFDZLwqfG809c1VQk",
	"editWidgetControlsContent": "_2vwRxf2DcD5JZ4L1mYIPAj",
	"gridContainer": "_1OtzAo-P7_UObrnhVSD-_S",
	"QueryControls": "_2LYYZCVpn8KOHcrQeeVAb7",
	"Visualization": "_2at144Njr8RXES-9Qoh5Fi",
	"Footer": "_3LyEBN4C2b31ykcXeMv6aW"
};