exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2V7NyG3-sz-_pi-q2WPQDK{font-size:18px;text-overflow:ellipsis;margin-bottom:5px}.dNcD3mzbtUsv8e878HOYs{position:relative;z-index:1}._2W0Xm5qXng5izRm-cKXp5d{cursor:move;opacity:.3}", ""]);

// exports
exports.locals = {
	"widgetHeader": "_2V7NyG3-sz-_pi-q2WPQDK",
	"widgetActionDropdown": "dNcD3mzbtUsv8e878HOYs",
	"widgetDragHandle": "_2W0Xm5qXng5izRm-cKXp5d"
};